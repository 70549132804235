<!--
View: DevelopmentServices
* @FileDescription: 研发服务
* @Author: ruixiaozi
* @Email: admin@ruixiaozi.com
* @Date: 2021年05月30日 13:59:27
* @Version: 版本
-->
<template>
  <div>
    <div class="title-bar">

    </div>
    <div class="content">
      <div class="title">研发需求</div>
      <el-form :model="formData" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-row :gutter="30">
          <el-col :span="8" :offset="0">
            <el-form-item label="公司名称" prop="company">
              <el-input v-model="formData.company"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item label="部门" prop="department">
              <el-input v-model="formData.department"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item label="项目名称" prop="project">
              <el-input v-model="formData.project"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="8" :offset="0">
            <el-form-item label="联系人" prop="personName">
              <el-input v-model="formData.personName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item label="电话" prop="phone">
              <el-input v-model="formData.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item label="职务" prop="job">
              <el-input v-model="formData.job"></el-input>
            </el-form-item>
          </el-col>
        </el-row>



        <el-row :gutter="30">
          <el-col :span="8" :offset="0">
            <el-form-item label="机组类型" prop="type">
              <el-select v-model="formData.type" placeholder="请选择机组类型">
                <el-option label="压气机" value="压气机"></el-option>
                <el-option label="涡轮" value="涡轮"></el-option>
                <el-option label="压气机+涡轮" value="压气机+涡轮"></el-option>
                <el-option label="水泵" value="水泵"></el-option>
                <el-option label="水轮机" value="水轮机"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row :gutter="30">
          <el-col :span="6" :offset="0">
            <el-form-item label="进口压力" prop="inputPressure">
              <el-input v-model="formData.inputPressure"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="进口温度" prop="inputTemp">
              <el-input v-model="formData.inputTemp"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="出口压力" prop="outputPerssure">
              <el-input v-model="formData.outputPerssure"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="流量" prop="flow">
              <el-input v-model="formData.flow"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="6" :offset="0">
            <el-form-item label="转速" prop="speed">
              <el-input v-model="formData.speed"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="效率要求" prop="efficiency">
              <el-input v-model="formData.efficiency"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="流量范围" prop="flowRange">
              <el-input v-model="formData.flowRange"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-form-item label="喘振裕度" prop="surge">
              <el-input v-model="formData.surge"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="4" :offset="0">
            <el-form-item label="海拔" prop="altitude">
              <el-input v-model="formData.altitude年平均气温"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="0">
            <el-form-item label="年平均气温" prop="avgTemavgTem">
              <el-input v-model="formData.avgTem"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="0">
            <el-form-item label="年平均大气压" prop="avePre">
              <el-input v-model="formData.avePre"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="0">
            <el-form-item label="年最高气温" prop="maxTem">
              <el-input v-model="formData.maxTem"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="0">
            <el-form-item label="年最低气温" prop="minTem">
              <el-input v-model="formData.minTem"></el-input>
            </el-form-item>

          </el-col>
        </el-row>
        <el-form-item label="其他要求说明" prop="others">
          <el-input type="textarea" :rows="10" v-model="formData.others"></el-input>
        </el-form-item>


        <el-form-item style="text-align:center">
          <el-button type="primary" style="width:70%" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Verify5 from 'verify5-html';
import axios from 'axios';

export default {
  // View name
  name: 'DevelopmentServices',
  // View props
  props: {

  },
  // Locally registered components
  components: {

  },
  // View status
  data() {
    return {
      rules: {
        company: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        department: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        project: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        personName: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        job: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        inputPressure: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        inputTemp: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        outputPerssure: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
        flow: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      formData: {
        company: '',
        department: '',
        project: '',
        personName: '',
        phone: '',
        job: '',
        type: '',//
        inputPressure: '',
        inputTemp: '',
        outputPerssure: '',
        flow: '',
        speed: '',
        efficiency: "",
        flowRange: "",
        surge: "",
        altitude: "",
        avgTem: "",
        avePre: "",
        maxTem: "",
        minTem: "",
        others: ""
      },

    }
  },
  // Calculate attribute
  computed: {

  },
  // View watch
  watch: {

  },
  // View methods
  methods: {
    submit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          this.$message.error('请完成必填项！');
          return false;
        }

        let v5 = new Verify5({
          host: "free7jysj6c2.verify5.com",
          token: "ecd56cd443b24954a6b2514692833601"
        });
        v5.verify((result) => {
          var success = result.success;
          if (success) {
            this.postData();
          } 

          
        });

      })
    },
    postData() {
      axios.post('/api/develop', this.formData).then(res => {
        console.log(res);
        if (res) {
          this.$alert('研发需求提交成功', '成功', {
            confirmButtonText: '确定',
            callback: () => {
              Object.keys(this.formData).forEach(key => {
                this.formData[key] = ''
              });
            }
          });
        } else {
          this.$message.error('提交失败！');
        }
      })
    }
  },
  // Lifecycle hooks
  // 路由切换，重新创建组件，（在这里获取数据）
  beforeRouteEnter(to, from, next) {

    next();
  },
  // 路由参数变化（或者重复点击当前路由导致参数变化），（在这里获取数据）
  beforeRouteUpdate(to, from, next) {

    next();
  },
};
</script>

<style lang="scss" scoped>
.title-bar {
  width: 100%;
  height: 150px;
  background-image: url('~@/assets/bg.png');
  background-size: cover;
  background-position: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  color: #1E90FF;
  margin-bottom: 50px;

  .title {
    padding: 30px 0px;
    text-align: center;
    font-size: 24px;
    width: 500px;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 10%;
      margin: 0px 10px;
      height: 1px;
      background-color: #1E90FF;
    }
  }

  .demo-ruleForm {
    width: 700px;
  }
}</style>
